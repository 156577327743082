<template>
  <ul
    class="product_preview-variants"
    :class="{ pdp: isPdp }"
    role="radiogroup"
    :aria-label="$t('product.color')">
    <li
      v-for="color in colorListSimplified"
      :key="color.title"
      class="color">
      <ColorItem
        :color="color"
        :isPdp="isPdp"
        :showColorTitle="false"
        @updateColor="updateColor"
        @loadSizes="loadSizes"
        @sendColorName="sendColorName"
        role="radio"
        :aria-label="color.title"
        :aria-checked="color.preselected"
        tabindex="0">
      </ColorItem>
    </li>
    <li v-if="showLinkToPDP">
      <div class="more-colors-link" :title="$t('global.view_more_colours')">
        <a :href="productURL">{{ linkToPDPText }} </a>
      </div>
    </li>
  </ul>
</template>

<script>
import ColorItem from 'CommonComponents/Product/Selector/Color/ColorItem.vue';
import { computed, inject, toRefs, onMounted } from 'vue';

export default {
  name: 'PreviewColorsComplex',
  props: {
    colorsCollection: {
      type: Array,
      required: true,
    },
    isPdp: {
      type: Boolean,
      required: true,
    },
    productUri: {
      type: String,
      default: '',
    },
  },
  emits: ['updateColor', 'loadSizes', 'sendColorName'],
  components: {
    ColorItem,
  },  
  setup(props, { emit }) {
        const updateColor = (color) => {
      emit('updateColor', color);
    };
    const loadSizes = () => {
      emit('loadSizes');
    };
    const sendColorName = (colorName) => {
      emit('sendColorName', colorName);
    };

    const { productUri } = toRefs(props);    

    const showLinkToPDP = computed(() => {
      return props.colorsCollection.length > 5 && !props.isPdp;
    });

    const colorListSimplified = computed(() => {
      const colorList = props.colorsCollection.filter(color => !color?.hideColor);

      if (!showLinkToPDP || props.isPdp) return colorList;
      return colorList.splice(0, 5);
    });

    const linkToPDPText = computed(() => {
      return '+' + (props.colorsCollection.length - 5).toString();
    });

    const productURL = computed(() => {
      if (productUri.value !== '') {
        return productUri.value;
      }
      const providedProductUri = inject('productUri');
      if (providedProductUri) {
        return providedProductUri;
      }
    })

    return {
      updateColor,
      loadSizes,
      showLinkToPDP,
      colorListSimplified,
      linkToPDPText,
      sendColorName,
      productURL      
    };
  },
};
</script>
